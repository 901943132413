@import url('https://themes.googleusercontent.com/fonts/css?kit=PuXA339oYpqMupCCjdRT64TiSMxo3C9J9zYJkorW4rR3Zg78zWL_hCDr58uKElz0AcKZhKzDO4Z5HHg4c9Rtof5Jgr8ufe5A6KahQF76Xmg');

.privacy-policy {
  h2 {
    margin-bottom: 1em;
  }

  h4 {
    margin-top: 2.5em;
    margin-bottom: .75em;
  }
}

.intk-privacy-doc {
  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10.5pt;
    font-family: "Open Sans";
    font-style: normal
  }

  .c5 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 24pt;
    font-family: "Montserrat";
    font-style: normal
  }

  .c8 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c10 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
  }

  .c0 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c4 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Open Sans";
    font-style: normal
  }

  .c7 {
    background-color: #ffffff;
    max-width: 451.3pt;
    padding: 72pt 72pt 72pt 72pt
  }

  .c1 {
    font-size: 10.5pt;
    font-weight: 700
  }

  .c9 {
    color: inherit;
    text-decoration: inherit
  }

  .c6 {
    font-weight: 400;
    font-size: 11pt
  }

  .c3 {
    font-size: 10.5pt
  }

  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 24pt;
    padding-bottom: 0pt;
    font-family: "Montserrat";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 14pt;
    padding-bottom: 10pt;
    font-family: "Oxygen";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Open Sans"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Open Sans"
  }

  h1 {
    padding-top: 10pt;
    color: #000000;
    font-size: 18pt;
    padding-bottom: 5pt;
    font-family: "Oxygen";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 0pt;
    font-family: "Oxygen";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 12pt;
    color: #666666;
    font-size: 14pt;
    padding-bottom: 0pt;
    font-family: "Exo 2";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 8pt;
    color: #666666;
    text-decoration: underline;
    font-size: 12pt;
    padding-bottom: 0pt;
    font-family: "Oxygen";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 8pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Oxygen";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 8pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Oxygen";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }
}