.intk-terms-doc {

  @import url('https://themes.googleusercontent.com/fonts/css?kit=PuXA339oYpqMupCCjdRT64TiSMxo3C9J9zYJkorW4rR3Zg78zWL_hCDr58uKElz0AcKZhKzDO4Z5HHg4c9Rtof5Jgr8ufe5A6KahQF76Xmg');

  .lst-kix_u5t03y6v6ipc-6 > li:before {
    content: "" counter(lst-ctn-kix_u5t03y6v6ipc-6, decimal) ". "
  }

  .lst-kix_u5t03y6v6ipc-8 > li:before {
    content: "" counter(lst-ctn-kix_u5t03y6v6ipc-8, lower-roman) ". "
  }

  .lst-kix_u5t03y6v6ipc-5 > li:before {
    content: "" counter(lst-ctn-kix_u5t03y6v6ipc-5, lower-roman) ". "
  }

  ol.lst-kix_u5t03y6v6ipc-7.start {
    counter-reset: lst-ctn-kix_u5t03y6v6ipc-7 0
  }

  .lst-kix_u5t03y6v6ipc-3 > li {
    counter-increment: lst-ctn-kix_u5t03y6v6ipc-3
  }

  .lst-kix_u5t03y6v6ipc-2 > li:before {
    content: "" counter(lst-ctn-kix_u5t03y6v6ipc-2, lower-roman) ". "
  }

  ol.lst-kix_u5t03y6v6ipc-2.start {
    counter-reset: lst-ctn-kix_u5t03y6v6ipc-2 0
  }

  .lst-kix_u5t03y6v6ipc-4 > li:before {
    content: "" counter(lst-ctn-kix_u5t03y6v6ipc-4, lower-latin) ". "
  }

  .lst-kix_u5t03y6v6ipc-6 > li {
    counter-increment: lst-ctn-kix_u5t03y6v6ipc-6
  }

  .lst-kix_u5t03y6v6ipc-1 > li {
    counter-increment: lst-ctn-kix_u5t03y6v6ipc-1
  }

  .lst-kix_u5t03y6v6ipc-3 > li:before {
    content: "" counter(lst-ctn-kix_u5t03y6v6ipc-3, decimal) ". "
  }

  .lst-kix_u5t03y6v6ipc-0 > li:before {
    content: "" counter(lst-ctn-kix_u5t03y6v6ipc-0, decimal) ". "
  }

  ol.lst-kix_u5t03y6v6ipc-5.start {
    counter-reset: lst-ctn-kix_u5t03y6v6ipc-5 0
  }

  .lst-kix_u5t03y6v6ipc-1 > li:before {
    content: "" counter(lst-ctn-kix_u5t03y6v6ipc-1, lower-latin) ". "
  }

  .lst-kix_u5t03y6v6ipc-8 > li {
    counter-increment: lst-ctn-kix_u5t03y6v6ipc-8
  }

  ol.lst-kix_u5t03y6v6ipc-0.start {
    counter-reset: lst-ctn-kix_u5t03y6v6ipc-0 0
  }

  ol.lst-kix_u5t03y6v6ipc-3.start {
    counter-reset: lst-ctn-kix_u5t03y6v6ipc-3 0
  }

  .lst-kix_u5t03y6v6ipc-2 > li {
    counter-increment: lst-ctn-kix_u5t03y6v6ipc-2
  }

  ol.lst-kix_u5t03y6v6ipc-8.start {
    counter-reset: lst-ctn-kix_u5t03y6v6ipc-8 0
  }

  .lst-kix_u5t03y6v6ipc-5 > li {
    counter-increment: lst-ctn-kix_u5t03y6v6ipc-5
  }

  ol.lst-kix_u5t03y6v6ipc-1.start {
    counter-reset: lst-ctn-kix_u5t03y6v6ipc-1 0
  }

  ol.lst-kix_u5t03y6v6ipc-6.start {
    counter-reset: lst-ctn-kix_u5t03y6v6ipc-6 0
  }

  ol.lst-kix_u5t03y6v6ipc-5 {
    list-style-type: none
  }

  ol.lst-kix_u5t03y6v6ipc-4.start {
    counter-reset: lst-ctn-kix_u5t03y6v6ipc-4 0
  }

  .lst-kix_u5t03y6v6ipc-4 > li {
    counter-increment: lst-ctn-kix_u5t03y6v6ipc-4
  }

  ol.lst-kix_u5t03y6v6ipc-6 {
    list-style-type: none
  }

  .lst-kix_u5t03y6v6ipc-7 > li {
    counter-increment: lst-ctn-kix_u5t03y6v6ipc-7
  }

  ol.lst-kix_u5t03y6v6ipc-3 {
    list-style-type: none
  }

  ol.lst-kix_u5t03y6v6ipc-4 {
    list-style-type: none
  }

  ol.lst-kix_u5t03y6v6ipc-1 {
    list-style-type: none
  }

  ol.lst-kix_u5t03y6v6ipc-2 {
    list-style-type: none
  }

  ol.lst-kix_u5t03y6v6ipc-0 {
    list-style-type: none
  }

  .lst-kix_u5t03y6v6ipc-0 > li {
    counter-increment: lst-ctn-kix_u5t03y6v6ipc-0
  }

  ol.lst-kix_u5t03y6v6ipc-7 {
    list-style-type: none
  }

  .lst-kix_u5t03y6v6ipc-7 > li:before {
    content: "" counter(lst-ctn-kix_u5t03y6v6ipc-7, lower-latin) ". "
  }

  ol.lst-kix_u5t03y6v6ipc-8 {
    list-style-type: none
  }

  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c5 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
  }

  .c4 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 10.5pt;
    font-family: "Open Sans";
    font-style: normal
  }

  .c11 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .c2 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 11pt;
    font-family: "Open Sans";
    font-style: normal
  }

  .c10 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 24pt;
    font-family: "Montserrat";
    font-style: normal
  }

  .c1 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.15;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c9 {
    color: #000000;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Open Sans";
    font-style: normal
  }

  .c0 {
    background-color: #ffffff;
    max-width: 451.3pt;
    padding: 72pt 72pt 72pt 72pt
  }

  .c8 {
    padding: 0;
    margin: 0
  }

  .c3 {
    font-size: 10.5pt;
    font-weight: 700
  }

  .c6 {
    margin-left: 36pt;
    padding-left: 0pt
  }

  .c7 {
    font-size: 10.5pt
  }

  .title {
    padding-top: 0pt;
    color: #000000;
    font-size: 24pt;
    padding-bottom: 0pt;
    font-family: "Montserrat";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  .subtitle {
    padding-top: 0pt;
    color: #666666;
    font-size: 14pt;
    padding-bottom: 10pt;
    font-family: "Oxygen";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: center
  }

  li {
    color: #000000;
    font-size: 11pt;
    font-family: "Open Sans"
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Open Sans"
  }

  h1 {
    padding-top: 10pt;
    color: #000000;
    font-size: 18pt;
    padding-bottom: 5pt;
    font-family: "Oxygen";
    line-height: 1.0;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 0pt;
    font-family: "Oxygen";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 12pt;
    color: #666666;
    font-size: 14pt;
    padding-bottom: 0pt;
    font-family: "Exo 2";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 8pt;
    color: #666666;
    text-decoration: underline;
    font-size: 12pt;
    padding-bottom: 0pt;
    font-family: "Oxygen";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 8pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Oxygen";
    line-height: 1.15;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 8pt;
    color: #666666;
    font-size: 11pt;
    padding-bottom: 0pt;
    font-family: "Oxygen";
    line-height: 1.15;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

}