/*
This file is part of Intake24.

© Crown copyright, 2012, 2013, 2014.

This software is licensed under the Open Government Licence 3.0:

http://www.nationalarchives.gov.uk/doc/open-government-licence/
*/

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 300;
	src: local('Open Sans Light'), local('OpenSans-Light'),
	url('../fonts/open-sans-v17-latin-300.woff2') format('woff2'),
	url('../fonts/open-sans-v17-latin-300.woff') format('woff');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
	url('../fonts/open-sans-v17-latin-regular.woff2') format('woff2'),
	url('../fonts/open-sans-v17-latin-regular.woff') format('woff');
}

body {
	font-family: 'Open Sans', sans-serif;
	padding-bottom: 20px;
}

h1, h2, h3, h4, h4, h6 {
	font-weight: 300;
}

.logo {
	padding: 10px 15px;
	float: right;
}

.navbar {
	position: absolute;
	width: 100%;
	background: rgba(0, 0, 0, 0.075);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	height: 50px;
}

.navbar a {
	color: white;
	font-size: 16px;
}

.navbar-nav li.active {
	background: rgba(255, 255, 255, 0.3);
}

.navbar-nav li>a:hover, .navbar-nav li>a:focus {
	color: white;
	background: rgba(255, 255, 255, 0.2);
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: rgba(255, 255, 255, 0.2);
    border-color: rgba(0,0,0,1);
}

.navbar-nav > li > .dropdown-menu {
    background-color: rgba(156, 121, 101, 0.9);
}

#navbar.collapsing {
    background-color: rgba(156, 121, 101, 0.9);
}

#navbar.in {
    background-color: rgba(156, 121, 101, 0.9);
}

.dropdown-menu > li > a {
	color: #ffffff;
}

.home-banner {
	background-image: url("../images/home-banner.jpg");
	background-size: cover;
	background-color: #CF3708;
	padding-top: 64px;
	padding-bottom: 16px;
	color: white;
}

.jumbotron h1 {
	font-size: 52px;
}

.jumbotron p {
	max-width: 500px;
}

.btn-success {
    color: #fff;
    background-color: #6ca76c;
    border-color: #6ca76c;
}

ul.dash {
  list-style-type: none;
}
ul.dash > li {
  margin-bottom: 1em;
}
ul.dash > li:before {
  content: "—";
  margin-right: 5px;
}

.navbar-toggle .icon-bar {
    background: white;
}

.btn-success {
	margin-bottom: 5px;
}